.step1{
  section{
    margin: 0 $margin-md $margin-sm $margin-md;
    border-radius: $radius;

    @media screen and (min-width: #{$sm-screen}) {
      margin: $margin-sm;
    }
  }

  .dimension {
    .section-title{
      margin-bottom: 0;
      padding-bottom: 0;
    }
    section {
      padding: $margin-md;
    }
  }

  .list-options{

    h2{
      padding: $margin-md;
      line-height: 30px;
      @media screen and (min-width: #{$sm-screen}) {
        padding: $margin-md $margin-sm $margin-sm $margin-sm;
      }
    }

    .container-switch{
      .switch-field{
        margin: $margin-md 0;
      }
    }

    .standiste{
      padding: $margin-md 0;

      .section-title{
        padding: 0;
      }

      .container-inputs{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .container-input{
          width: 100%;
        }
        @media screen and (min-width: #{$sm-screen}) {
          flex-direction: row;
          .container-input{
            width: calc(33% - 5px);
          }
        }
      }

    }
  }
  
  .price-with-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: $margin-sm;

    .errors {
      width: 100%;
      p {
        font-family: "Montserrat-SemiBold";
        margin: 0 0 0 15px;
        color: $primary-color;
        text-align: center;
      }

      @media screen and (min-width: $sm-screen) {
        width: auto;
        p {
          text-align: left;
        }
      }
    }

    p {
      margin-left: auto;
      margin-bottom: 0;
      span:first-child {
        position: relative;
        font-family: "Montserrat-SemiBold";
        top: -5px;
      }
      span:last-child {
        font-family: "Montserrat-SemiBold";
        font-size: 30px;
        color: $secondary-color;
      }
    }
  }

  .container-submit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-top: $margin-sm;

    button{
      margin: 0;
      &.loading{
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    p.warn{
      margin: 0 $margin-sm;
    }
  }

}
