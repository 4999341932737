@import '../../../../../scss/variables.scss';

.confirmSubscribe {
  display: flex;
  align-items: center;
  flex-direction: column;

  h3 {
    font-family: 'Montserrat-SemiBold';
  }

  img {
    width: 100%;
    max-width: 500px;
    margin-bottom: $margin-lg;
  }
}