.products-provider {
  overflow-y: auto;
  $height-nav: 64px;
  height: calc(100% - #{$height-nav}) !important;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - 90px) !important;
  }

  .title-page {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding-top: 0;
    margin-bottom: $margin-xs;
    // @media screen and (max-width: #{$md-screen}) {
    //   h2{ display: none !important; }
    // }
    @media screen and (max-width: #{$sm-screen}) {
      padding: 0 $margin-sm;
    }

    .btn-export {
      margin-left: auto;
    }

    a {
      margin-bottom: 0;
    }

    a > button {
      margin-bottom: 0;
      margin-left: $margin-xs;
      min-width: 100px;
      @media screen and (max-width: $xs-screen) {
        padding: 0 $margin-xs;
      }
    }
  }

  @include listProducts();


  .container-ca {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: $margin-sm;



    .title-ca {
      color: $secondary-color;
      h2 {
        margin-top: 0;
        margin-bottom: $margin-xs;
      }
      p {
        margin-bottom: 0;
      }

      +.btn-export {
        margin-left: $margin-sm;
      }

    }

    button {
      margin-bottom: 0;
    }

    @media screen and (max-width: #{$sm-screen}) {
      padding: 0 $margin-sm;
    }
  }

  .list-products {

    .sortBy .product-status {
      // display: none !important;
      // width: 100px;
      //.icon-sort {
        //position: relative;
        //top: -3px;
        //left: -5px;
        //&:hover {
          //margin-left: 5px;
        //}
      //}
    }

    .product-ref-brand {
      display: block;
      width: 50%;
      p {
        font-size: $regular;
      }
      p:not(:last-child) {
        color: $text-color;
        font-family: "Montserrat-SemiBold";
      }
      p:last-child {
        color: $md-grey;
        font-family: "Montserrat-Regular";
      }

    }

    .product-status {
      .icon {
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: 20px;
        margin-right: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.valid {
          border: 1px solid #13B467;
          background-color: rgba(#13B467, 0.2);
          img {
            transform: scale(0.7);
            width: 20px;
            height: 20px;
          }
        }
        &.confirmed {
          border: 1px solid #13B467;
          background-color: rgba(#13B467, 0.2);
          img {
            transform: scale(0.7);
            width: 20px;
            height: 20px;
          }
        }
        &.pending {
          border: 1px solid #FF8900;
          background-color: rgba(#FF8900, 0.2);
        }
        &.refused {
          border: 1px solid #E32A2A;
          background-color: rgba(#E32A2A, 0.2);
          img {
            transform: scale(0.7);
            width: 20px;
            height: 20px;
          }
        }
      }
    }

    .product-packing-qty {
      display: block;
      text-align: right;
      width: 50%;
      p:last-child {
        color: $secondary-color;
        font-family: "Montserrat-SemiBold";
      }
    }

    .product-price {
      min-width: 120px !important;
      width: 120px !important;
    }
    // ul .product-qty,
    // ul .product-brand,
    // ul .product-packing,
    // .sortBy > div.product-packing,
    // .sortBy > div:last-child {
    //   max-width : inherit !important;
    // }
    //
    // ul .product-qty{
    //   justify-content: flex-start !important;
    // }
  }
}
