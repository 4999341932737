@import '../../../scss/variables.scss';

.recapitulatif {

  .sectionTitle {
    padding-bottom: 0;
  }
  section{
    display: flex;
    background-color: $secondary-color;
    flex-direction: column;
    padding: 0;

    @media screen and (min-width: #{$sm-screen}) {
      flex-direction: row;
    }

    .dimension{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: $margin-sm;
      margin: $margin-sm 0;
      box-sizing: border-box;
    }

    .dimension{
      border-left: none;
      max-width: 100%;
      margin: 0;

      p.warn{
        text-align: center;
      }

      @media screen and (min-width: $sm-screen) {
        border-top: none;
        width: 100%;
        max-width: 230px;
        // margin: $margin-md 0;
      }

      .info-dimension{
        color : $white;
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;

        .stand {
          width: 90px;
        }

        .hide-on-mobile{
          @media screen and (min-width: #{$sm-screen}){
            display: inline-block !important;
          }
        }

        @media screen and (min-width: 390px) {
          flex-direction: row-reverse;
        }

        @media screen and (min-width: #{$sm-screen}) {
          flex-direction: column;
        }

        p{
          margin-bottom: 0;
          text-align: center;
          @media screen and (min-width: 390px) {
            text-align: left;
          }
          @media screen and (min-width: #{$sm-screen}) {
            text-align: center;
          }
        }

        .dimension{
          font-family: "Montserrat-SemiBold";
          font-size: 50px;

          span{
            font-size: $regular;
          }
          @media screen and (min-width: #{$sm-screen}) {
            font-size: 40px;
          }
        }

        .count-emplacement{
          font-family: "Montserrat-SemiBold";
          font-size: $h1;
          padding: $margin-sm;
          @media screen and (min-width: #{$sm-screen}) {
            font-size: 90px;
          }
        }
      }
    }

    .negotiatedPrice {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: $margin-sm;
      margin: 0 0 $margin-sm 0;
      box-sizing: border-box;
      border-left: none;
      border-top: solid 1px darken($secondary-color,10%);

      @media screen and (min-width: #{$sm-screen}) {
        border-left: solid 1px darken($secondary-color,10%);
        border-top: none;
        margin: $margin-sm 0;
      }

      p {
        color: $white;
      }

      p:last-child {
        font-family: "Montserrat-SemiBold";
        font-size: 50px;
        margin-bottom: 0;
        @media screen and (min-width: #{$sm-screen}) {
          font-size: 40px;
        }
      }


    }

    .recap-options{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: $margin-sm $margin-md;
      margin: 0 0 $margin-sm 0;
      box-sizing: border-box;
      align-items: flex-start;
      border-left: none;
      border-top: solid 1px darken($secondary-color,10%);

      @media screen and (min-width: #{$sm-screen}) {
        border-left: solid 1px darken($secondary-color,10%);
        border-top: none;
        margin: $margin-sm 0;
      }


      .label-presta{
        font-family: "Montserrat-SemiBold";
        color: $primary-color;
        margin-top: $margin-sm;
      }

      .container-presta{
        background-color: darken($secondary-color,10%);
        padding: $margin-xs 20px;
        margin-bottom: $margin-sm;
        border-radius: 8px;
        width: 100%;

        p{
          font-family: "Montserrat-Regular";
          color : white;
        }

        a{
          line-height: 20px;
        }

        .bold{
          font-family: "Montserrat-SemiBold";
        }

        .presta{
          color : white;
          margin-top: $margin-md;
        }
      }



      p{
        font-family: "Montserrat-SemiBold";
        color : $white;
        margin: $margin-xs 0;
        .sub{
          color : $white;
        }
        &.warn,.warn{
          color : $primary-color;
        }
      }

    }

  }


}
