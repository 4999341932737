@import '../../../scss/variables.scss';

.networkIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background-color: rgba(#161B46, 0.75);

  .container {
    width: 400px;
    background-color: $white;
    border-radius: 19px;
    margin: $margin-sm;
    padding: $margin-lg;
    text-align: center;

    img {
      width: 100%;
      max-width: 300px;
    }

    h3 {
      margin-bottom: $margin-lg;
    }

  }

}