@import '../../../../scss/variables.scss';
@import '../../../../scss/mixins.scss';

.formProduct {
  overflow-y: auto;
  $height-nav: 90px;
  height: calc(100% - #{$height-nav}) !important;
  padding: $margin-sm $margin-md $margin-md $margin-md;

  @include loader();

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin-sm;
  }

  .error {
    font-family: 'Montserrat-Medium';
    color: $error;
    margin-top: -10px;
  }

  .form {
    margin-bottom: $margin-sm;
    @media screen and (min-width: $md-screen) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: $margin-sm;
  
      .info {
        grid-area: 1 / 1 / 3 / 2;
      }
    
      .delivery {
        grid-area: 1 / 2 / 2 / 3;
      }
    
      .price {
        grid-area: 2 / 2 / 3 / 3;
      }
      
    }

  }

  .form .info,
  .form .delivery,
  .form .price {
    background-color: $white;
    padding: $margin-xs $margin-md $margin-md $margin-md;
    border-radius: 5px;
    box-shadow: 0px 4px 18px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: $margin-md;

    @media screen and (min-width: $md-screen) {
      margin-bottom: 0;
    }

    h4 {
      font-family: 'Montserrat-SemiBold';
      color: $secondary-color;
      font-size: $medium;
      margin: $margin-sm 0 $margin-sm 0;
    }

    label {
      font-family: 'Montserrat-SemiBold';
      color: $text-color;
    }

    .row {
      display: flex;

      .status {
        position: relative;
        top: -3px;
        display: inline-block;
        text-align: center;
        padding: $margin-xs $margin-sm;
        height: 38px;
        border-radius: 19px;
        font-family: 'Montserrat-SemiBold';
        color: $white;
        margin: $margin-sm 0 $margin-sm auto;

        &.valid {
          background-color: $green;
        }

        &.pending {
          background-color: $primary-color;
        }

        &.refused {
          background-color: $error;
        }

        &.correction {
          background-color: #FCD12A;
        }

      }

      label {
        display: block;
      }

      p.sub {
        font-family: 'Montserrat-SemiBold';
        color: $text-disabled-color;
        margin-bottom: $margin-sm;
      }

      >div {
        &:not(:last-child) {
          margin-right: $margin-sm;
        }
        input {
          max-width: inherit;
        }

        &.selectDelivery {
          width: 100%;

          label {
            position: relative;
            > span {
              position: absolute;
              top: -4px;
              // vertical-align: middle;
              // display: none;
            }
          }

          @media screen and (min-width: $sm-screen) {
            max-width: calc(50% - 10px);
          }

        }
      }
    }

  }

  .form .price .more {
    margin-top: $margin-sm;
    ul {
      display: flex;
      align-items: center;
      justify-content: start;
      flex-wrap: wrap;
      list-style: none;
      margin: $margin-sm 0;
      padding: 0;

      &.disabled .circle,
      &.disabled p {
        cursor: inherit;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $margin-sm;

        @media screen and (min-width: $md-screen) {
          width: 33%;
        }

        p {
          margin: 0 0 0 5px;
          padding: 0 5px;
          width: 100px;
          cursor: pointer;
        }

        &:first-child .circle {
          img {
            width: 30px;
          }
        }

        &:last-child .circle {
          img {
            width: 40px;
          }
        }

        .circle {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 75px;
          min-width: 75px;
          height: 75px;
          background-color: #BEBEBE;
          border-radius: 50%;
          cursor: pointer;

          &.selected {
            background-color: $primary-color;
          }

          img {
            width: 50px;
          }
        }
      }
    }
  }

  .delete {
    text-align: center;
    p {
      font-family: 'Montserrat-Medium';
      color: $error;
      text-decoration: underline;
      cursor: pointer;
      
      svg {
        position: relative;
        top: 5px;
        margin-right: 3px;
      }
    }
  }
}