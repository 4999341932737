@import './list-products-orders.scss';

.orders{
  $height-nav : 64px;
  height: calc(100% - #{$height-nav}) !important;
  overflow-y: auto;
  // for app
  // overflow-y: scroll;

  @media screen and (min-width: #{$md-screen}) {
    height: calc(100% - 90px) !important;
  }

  @include inputNumber();

  .loader{
    position: fixed;
    top:50%;
    left:50%;
  }

  .content{
    padding-bottom: $margin-md;
  }

  p b{
    font-family: "Montserrat-Bold"
  }

  .title-page{
    .right{
      float: right;
    }
  }

  .filters{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0;

    &:not(.fixed)+.title-provider{
      margin-top: $margin-sm;
    }

    &.fixed+.title-provider{
      margin-top: 60px;
      @media screen and (min-width: #{$md-screen}) {
        margin-top: 80px;
      }
    }

    &.fixed{
      position: fixed;
      z-index: 1;
      top: 60px;
      background-color: $light-grey;
      max-width: 1224px;
      width: 100%;

      @media screen and (min-width: #{$sm-screen}) {
        width: 90%;
      }

      @media screen and (min-width: #{$md-screen}) {
        top: 90px;
      }

      &:after,&:before{
        content : "";
        position: absolute;
        top:0;
        display: block;
        height: 100%;
        width: 200px;
        background-color: $light-grey;
      }
      &:after{
        right: 0;
        transform: translateX(100%);
      }
      &:before{
        left: 0;
        transform: translateX(-100%);
      }

    }

    @media screen and (max-width: #{$sm-screen}){
      padding: 0 $margin-sm;
    }

    @media screen and (min-width: 650px){
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .css-2b097c-container,.field-search{
      min-width: 100%;
      max-width: 100%;
      @media screen and (min-width: 650px){
        min-width: 300px;
        max-width: calc(100% - 330px);
      }
    }

    .field-search{
      position: relative;
      display: inline-block;
      margin-right: $margin-sm;
      max-width: 300px;
      input{
        padding-right: 35px;
      }

      img{
        position: absolute;
        top:50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
  }

  .title-provider{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: $margin-md 0 $margin-sm 0;

    @media screen and (min-width: #{$md-screen}){
      margin-bottom: 0;
    }

    >div{
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: #{$sm-screen}){
        padding: 0 $margin-sm;
        width: 100%;
      }
      >p{
        font-family: "Montserrat-Regular";
        color : $text-color;
        margin: 0;
        font-size: $medium;
      }
      .price{
        font-family: "Montserrat-Bold";
        color : $secondary-color;
        margin-left: $margin-sm;
      }
    }

    @media screen and (max-width: 380px){
      div:nth-child(2){
        flex-direction: column;
        align-items: flex-end;
        button{
          margin-bottom: $margin-xs
        }
      }
    }

    @media screen and (max-width: #{$sm-screen}){
      div:nth-child(2){
        justify-content: flex-start;
        margin-top: $margin-xs;
      }
    }


    a {
      margin: 0;
    }

    button{
      margin: 0;
      @media screen and (max-width: #{$sm-screen}){
        padding: 0 $margin-sm;
        min-width: 200px;
        white-space: nowrap;
      }
    }

  }

  .title-validate{
    margin: 0 $margin-sm;
    @media screen and (min-width: #{$sm-screen}){
      margin: 0;
    }
  }

  .confirmed-order{
    display: flex;
    flex-direction: column;
    padding: $margin-sm;
    border-radius: $radius;
    margin: 0 $margin-sm $margin-sm $margin-sm;
    background-color: $white;

    @media screen and (min-width: #{$sm-screen}){
      margin: 0 0 $margin-sm 0;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    @include shadow();
    @include remove-tap-highlight();
    p,a{
      margin: 0;
    }

    .total-tablet{
      font-family: "Montserrat-Bold";
      color : $secondary-color;
      font-size: $medium;
      display: inline;
      @media screen and (min-width: $sm-screen){
        display: none !important;
      }
    }


    >p{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-family: "Montserrat-Regular";
      color : $text-color;
      font-size: $medium;
      margin: 0;
      @media screen and (min-width: $sm-screen){
        display: block;
      }
    }
    >div{
      display: inline-flex;
      flex-direction: column;
      margin-top: $margin-sm;

      @media screen and (min-width: 490px){
        flex-direction: row;
        align-items: center;
      }
      
      @media screen and (min-width: #{$sm-screen}){
       margin: 0;
      }

      a:first-child{
        margin: 0 0 $margin-sm  0;
        @media screen and (min-width: 490px){
          flex-wrap: nowrap;
          margin: 0 $margin-sm 0 0;
        }
      }

      >p{
        font-family: "Montserrat-Bold";
        color : $secondary-color;
        margin-left: $margin-sm;
        font-size: $medium;
      }
      button{
        margin: 0;
        background-color: transparent;
        color: $primary-color;
      }
    }
  }

}
