@import '../../../../scss/variables.scss';

.scanProvider {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .scanner {
    position: absolute;
    top: 0px;
    left: 50%;
    max-width: 450px;
    width: 100%;
    height: 100%;
    transform: translate(-50%, 0%);  

    @media screen and (min-width: 450px) {
      top: 45%;
      transform: translate(-50%, -50%);  
      max-height: 450px;
    }

    >section {
      height: 100%;
      >section {
        height: 100%;
        padding-top: 0;

        >div {
          border-width: 30px !important;
          border-bottom-width: 80px !important;
          @media screen and (min-width: 450px) {
            border-width: 30px !important;
          }
        }

      }
    }

    .submit {
      position: absolute;
      z-index: 2;
      bottom: 0;
      text-align: center;
      width: 100%;
      @media screen and (min-width: 450px) {
        position: relative;
        margin-top: $margin-sm;
      }
    }

    .loader>div {
      z-index: 3;
    }

  }

  .success {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 70px;
  }

  .failed {
    text-align: center;
    margin-bottom: 70px;
  }

}
