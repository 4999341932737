@import '../../../../scss/variables.scss';

.visits {
  $height-nav : 64px;
  position: relative;
  height: calc(100% - 70px) !important;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  // padding-bottom: $margin-sm;
  padding: 0 $margin-sm $margin-md $margin-sm;

  @media screen and (min-width: $md-screen) {
    height: calc(100% - 90px) !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .list {
    margin: 0;
    padding: 0;
    .visit {
      display: flex;
      align-items: center;
      justify-content: center;
      list-style: none;
      border-radius: 5px;
      border: 1px solid $primary-color;
      background: #FFF;
      padding: 5px $margin-sm 5px 5px;
      margin-bottom: $margin-xs;

      .shoppingCart {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        background-color: $secondary-color;
        width: 35px;
        height: 35px;
        border-radius: 3px;
      }

      .information {
 
        @media screen and (min-width: $sm-screen) {
          display: flex;
          justify-content: center;
          .name {
            white-space: nowrap;
            width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            border-right: solid 1px $md-grey;
            padding: 4px 0;
          }
          .userName {
            padding: 4px 0;
          }
        }

        .name {
          font-family: "Montserrat-SemiBold";
          margin-left: $margin-xs;
          margin-bottom: 0;
          padding: 4px 0 0 0;
        }
  
        .userName {
          margin-left: $margin-xs;
          margin-bottom: 0;
          padding: 4px 0;
        }
      }



      .date {
        margin-left: auto;
        margin-bottom: 0;
        color: $text-disabled-color;
        line-height: 20px;
        height: 20px;
        svg {
          vertical-align: bottom;
          margin-right: 5px;
        }
      }
    }
  }

}
