@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.page {
  position: relative;
  height: 100%;
  overflow-y: auto;

  .error {
    font-family: "Montserrat-Bold";
    color: $error;
  }

  .content {
    position: relative;
    box-sizing: border-box;
    max-width: 1124px;
    margin: 0 auto;
    width: 100%;
    @media screen and (min-width: $sm-screen) {
      width: 90%;
    }

    .header {
      margin: $margin-md 0 0 0;
      padding: 0 $margin-sm;
      @media screen and (min-width: $xs-screen) {
        padding: 0 $margin-md;
      }
      @media screen and (min-width: 600px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 $margin-md;
        margin: $margin-md 0;
      }

      @media screen and (min-width: $sm-screen) {
        padding: 0;
      }
      h1 {
        font-family: "Montserrat-SemiBold";
        font-size: $h2;
        color: $text-color;
        font-weight: normal;
        margin: 0 $margin-sm $margin-sm 0;
        @media screen and (min-width: 600px) {
          font-size: $h1;
          margin: 0;
        }
      }

    }

    .contentForm {
      margin-bottom: $margin-md;
    }

    .contentForm h2 {
      font-family: "Montserrat-SemiBold";
      color: $secondary-color;
      font-size: $medium;
      padding: 0 $margin-sm;
      @media screen and (min-width: $xs-screen) {
        padding: 0 $margin-md;
      }
      @media screen and (min-width: $sm-screen) {
        padding: 0;
      }
    }

    .contentForm .form {
      background-color: $white;
      padding: $margin-sm;
      box-shadow: 0px 4px 34.4px 0px rgba(0, 0, 0, 0.05);

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      @media screen and (min-width: $xs-screen) {
        padding: $margin-md;
      }
      .row label,
      .rowProducts label {
        font-family: "Montserrat-SemiBold";
        display: inline-block;
      }

      .row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $margin-sm;

        @media screen and (min-width: 1000px) {
          grid-template-columns: 1fr 1fr 1fr;
        }

      }

    }

    .contentForm+button {
      margin-bottom: $margin-lg;
    }

    button {
      min-width: 185px;
      &.disabled {
        border: solid 1px rgba(200,200,205,1) !important;
        background-color: rgba(200,200,205,1) !important;
        pointer-events: none;
      }
    }

  }
}