@import '../../../scss/variables.scss';
@import '../../../scss/mixins.scss';

.page {
  position: relative;
  height: 100%;
  scrollbar-gutter: stable;

  .content {
    position: relative;
    box-sizing: border-box;
    max-width: 1124px;
    margin: 0 auto;
    width: 100%;
    min-height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: fadeIn 0.3s ease-in-out;

    @media screen and (min-width: $sm-screen) {
      width: 90%;
    }

    h1 {
      text-align: center;
      color: $secondary-color;
    }
    h4 {
      text-align: center;
      color: $secondary-color;
      margin-bottom: $margin-md;
    }

    img {
      max-width: 500px;
    }

    button {
      margin-top: $margin-md;
    }

  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translate(0, 100px);
    }

    100% {
      opacity: 1;
      transform: translate(0, 0px);
    }
  }

}