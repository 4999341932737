.overlay-modal{
  position: fixed;
  z-index: 1002;
  top:0;
  height: 100%;
  width: 100%;
  background-color: rgba(26, 27, 66, .6);
}

.modal{
  position: fixed;
  z-index: 1003;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    max-width: 30px;
    min-width: inherit;
    height: 30px;
    border-radius: 50%;
    transform: translate(50%, -50%);
    margin: 0;
    padding: 0;
    font-size: 28px;
    cursor: pointer;
    svg {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}
